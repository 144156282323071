<template>
  <div class="panel">
    <div class="panel-heading">
      <h1 class="title">Versengők tabellája</h1>
      <h2 class="subtitle">Tippelj és kerülj az élmezőnybe!</h2>
    </div>
    <div class="panel-block">
      <div class="table is-fullwidth mb-4">
        <table class="table stat is-fullwidth">
          <tr>
            <th class="has-text-left">#</th>
            <th class="has-text-left">Név</th>
            <th class="has-text-right">Pont</th>
          </tr>
          <tr v-for="(e, i) in users" :key="i">
            <td class="place has-text-left">
              {{ i+1 }}
            </td>
            <td class="name has-text-left">
              <!--
              <span v-if="e.data().place > i+1" class="has-text-success" >
                <span class="material-icons-outlined">
                  arrow_upward
                </span>
              </span>
              <span v-else-if="e.data().place < i+1" class="has-text-danger">
                <span class="material-icons-outlined">
                  arrow_downward
                </span>
              </span>
              <span v-else>
                <span class="material-icons-outlined">
                  arrow_right_alt
                </span>
              </span>
              -->
              {{ e.data().name }}
            </td>
            <td class="point has-text-right">{{ e.data().points }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propUsers: Array
  },
  data() {
    return {
      users: this.propUsers
    }
  },
  created() {

  }
}
</script>

<style lang="css" scoped>
</style>
