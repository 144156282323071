<template>
  <section class="hero is-primary is-fullheight signup">
    <div class="hero-body">

      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8-tablet is-6-desktop is-6-widescreen">
            <div class="card">
              <header class="card-header has-background-dark has-text-light is-size-6 p-3">
                Regisztráció
              </header>
              <div class="box">
                <form class="form" v-on:submit.prevent="signup" method="post">
                  <div class="field">
                    <label for="" class="label">Hogyan szólítsunk?</label>
                    <div class="control has-icons-left">
                      <input type="text" placeholder="John Doe" v-model="name" class="input" required>
                      <span class="icon is-small is-left">
                        <span class="material-icons-outlined">
                          account_circle
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="field">
                    <label for="" class="label">Email</label>
                    <div class="control has-icons-left">
                      <input type="email" placeholder="e.g. bobsmith@gmail.com" v-model="email" class="input" required>
                      <span class="icon is-small is-left">
                        <span class="material-icons-outlined">
                          email
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control has-icons-left">
                      <input type="password" placeholder="*******" v-model="password" class="input" required>
                      <span class="icon is-small is-left">
                        <span class="material-icons-outlined">
                          lock
                        </span>
                      </span>
                    </div>
                  </div>

                  <div class="field form-buttons mt-5">
                    <input type="submit" class="button is-primary" value="Regisztráció" />
                    <div class="mt-3 has-text-centered">
                      <router-link class="button is-ghost is-small" :to="{ name: 'Login', params: {} }">Vissza a bejelentkezésre</router-link>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  title: 'Worksdot EB21 - Regisztráció',
  name: 'Signup',
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //firebase.auth().signOut()
        //this.$router.replace({ name: 'Login'})
      }
    })
  },
  data() {
    return {
      name: '',
      email: '',
      password: ''
    }
  },
  methods: {
    signup: function() {
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(authenticate=>{
        return authenticate.user.updateProfile({
          displayName: this.name
        }).then(()=>{
          //console.log(authenticate.user.uid)
          const user = {
            id: authenticate.user.uid,
            name: authenticate.user.displayName,
            email: authenticate.user.email,
            points: 0,
            admin: false,
            place: ''
          }
          firebase.firestore().collection('users').add(user)
          firebase.auth().signOut()
          this.$router.replace({ name: 'Login' })
        })
        //location.reload()
        //this.$router.replace({ name: 'Home' })
      }).catch(error=>{
        alert(error.message)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
