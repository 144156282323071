<template>
  <div class="columns is-multiline">
    <div class="column is-8-desktop is-12-tablet">
      <div class="card height-100 is-color">
        <div class="card-content">
          <div class="media">
            <div class="media-left is-hidden-mobile">
              <figure class="image">
                <img class="hero-image" src="../assets/images/2502796.jpg" alt="" />
              </figure>
            </div>
            <div class="media-content">
              <span class="title is-4">Szabályzat és leírás</span>
              <p class="mt-2 pb-2">
                A tippedet a mérkőzések kezdetéig tudod leadni, akár több meccsre is egyszerre.
                Ahhoz, hogy pontot szerezz, csupán el kell találnod a meccsek nyertesét, vagy éppen a döntetlen eredményt.
                <!-- 1: egyes csapat nyer, X: döntetlen, 2: kettes csapat nyer -->
                A rendes játékidő (azaz a 90 perc) lefújásáig elért eredményre kell tippelni. </p>
                <!-- A felület jobb oldalán követheted a saját pontjaidat, illetve az összesített ranglistát is itt találod. -->
            </div>
          </div>
          <article class="message is-info">
            <div class="message-body">
              Példa: A Németország-Spanyolország mérkőzés eredménye a rendes játékidőben 1:1 lett. A hosszabbításban a spanyolok lőttek egy gólt, így ők jutottak tovább.
              A játék szempontjából azonban a döntetlen eredményt kell figyelembe venni, így az kap pontot, aki X-et tippelt.
            </div>
          </article>

          <article class="feature-message message is-warning mt-4">
            <div class="message-body">
              <strong>Megjelent a szorzó feature</strong>, ami azt mutatja, hogy a meccs pontja mennyivel lesz felszorozva, ha sikeresen megtippeled a rendes játékidő alatt született végeredményt.
              <strong>Minél nagyobb a szorzó, annál nagyobb a kockázat</strong>. De ne hagyd magad manipulálni! :)
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="card is-color height-100">
        <div class="card-content point-text-wrapper">
          <span class="title is-4">pontok számítása</span>
          <p class="mt-2">
            <span class="info">
              <span class="info-title">Csoportmérkőzések:</span>
            </span>
            <span class="info-value">2 pont</span>
          </p>
          <p>
            <span class="info">
              <span class="info-title">Legjobb 16 és 8 között:</span>
            </span>
            <span class="info-value">3 pont</span>
          </p>
          <p>
            <span class="info">
              <span class="info-title is-6">Legjobb 4 között:</span>
            </span>
            <span class="info-value">4 pont</span>
          </p>
          <p>
            <span class="info">
              <span class="info-title">Döntő:</span>
            </span>
            <span class="info-value">6 pont</span>
          </p>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
