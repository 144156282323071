<template>
  <section>
    <div class="modal" v-bind:class="{'is-active': isModal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Új meccs létrehozása</p>
          <button class="delete" aria-label="close" @click="isModal=!isModal"></button>
        </header>

          <form class="" v-on:submit.prevent="addMatch" method="post">
            <section class="modal-card-body new-match">

              <div class="columns is-flex-mobile is-multiline">
                <div class="column is-6-mobile">
                  <div class="field">
                    <label for="" class="label">Hazai csapat</label>
                    <div class="select">
                      <select v-model="match.home" required>
                        <option value="" selected>Válassz egy hazai csapatot!</option>
                        <option v-for="(option, i) in teams" :key="i" v-if="teams.length" v-bind:value="option.id">
                          {{ option.data().name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="column is-6-mobile">
                  <div class="field">
                    <label for="" class="label">Vendég csapat</label>
                    <div class="control">
                      <div class="select">
                        <select v-model="match.guest" required>
                          <option value="" selected>Válassz egy vendég csapatot!</option>
                          <option v-for="(option, i) in teams" :key="i" v-if="teams.length" v-bind:value="option.id">
                            {{ option.data().name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-6-mobile">
                  <div class="field date-picker">
                    <label class="label" for="">Meccs kezdete</label>
                    <div class="control">
                      <date-picker v-model="match.start" type="datetime" required></date-picker>
                    </div>
                  </div>
                </div>
                <div class="column is-6-mobile">
                  <div class="field">
                    <label class="label" for="">Pontozás</label>
                    <div class="control">
                      <div class="select">
                        <select v-model="match.score" required>
                          <option value="" selected>Válassz egy mérkőzés típust!</option>
                          <option value="2">Csoportmérkőzés</option>
                          <option value="3">Legjobb 16 vagy 8 között</option>
                          <option value="4">Legjobb 4 között</option>
                          <option value="6">Döntő</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns is-flex-mobile">
                <div class="column is-4-mobile">
                  <div class="field mr-4">
                    <label for="" class="label">xHome</label>
                    <div class="control">
                      <input class="input" type="text" v-model="match.xhome" required>
                    </div>
                  </div>
                </div>

                <div class="column is-4-mobile">
                  <div class="field mr-4">
                    <label for="" class="label">xDraw</label>
                    <div class="control">
                      <input class="input" type="text" v-model="match.xdraw" required>
                    </div>
                  </div>
                </div>

                <div class="column is-4-mobile">
                  <div class="field mr-4">
                    <label for="" class="label">xGuest</label>
                    <div class="control">
                      <input class="input" type="text" v-model="match.xguest" required>
                    </div>
                  </div>
                </div>

              </div>
            </section>

            <footer class="modal-card-foot">
              <button class="button is-primary" type="submit" name="button" v-bind:class="{'is-loading': isLoad}">Meccs mentése</button>
              <a class="button" @click="isModal=!isModal">Cancel</a>
            </footer>

          </form>


      </div>
    </div>


    <button class="button is-primary mr-4" type="button" name="button" @click="isModal=!isModal">Új meccs</button>
  </section>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      isLoad: false,
      isModal: false,
      teams: this.$parent.teams,
      match: {
        home: null,
        guest: null,
        start: null,
        matchResult: null,
        result: null,
        score: null,
        xhome: null,
        xdraw: null,
        xguest: null,
        xgood: null
      },
    }
  },
  methods: {
    addMatch () {
      if (!this.match.home || !this.match.guest || !this.match.start || !this.match.score) {
        console.log('na itt alljunk meg!')
        console.log(this.match.home)
        console.log(this.match.guest)
        console.log(this.match.start)
        console.log(this.match.score)
      } else {
        try {
          firebase.firestore().collection('match').add(this.match)
          this.$parent.getMatches()

          this.match.home = null
          this.match.guest = null
          this.match.start = null
          this.match.matchResult = null
          this.match.result = null
          this.match.score = null
          this.match.xhome = null
          this.match.xdraw = null
          this.match.xguest = null
          this.match.xgood = null

          this.$Msg.success('Meccs létrehozva', {
            position: 'top-right',
          })

          this.isLoad = false
          this.isModal = false

        } catch (err) {
          console.error('Error adding document: ', err)
        }
      }
    }

  }
}
</script>

<style lang="css" scoped>
</style>
