<template>
  <section>
    <div class="card-header">
      <div class="match-date is-size-7">
        <span class="icon material-icons-outlined">calendar_today</span>
        {{getShortDate(startTime).month}}.
        {{getShortDate(startTime).day}}.
        {{getShortDate(startTime).hours}}:{{getShortDate(startTime).minutes}}0
      </div>

      <div class="countdown-timer is-size-7">
        <vue-countdown-timer @end_callback="$parent.endCallBack(matchInt)" :start-time="startTime.seconds" :interval="1000">
          <template slot="countdown" slot-scope="scope">
            <div class="day">
              <span></span>
              <span>{{scope.props.days}}</span>
              <span>nap,</span>
            </div>
            <div class="time">
              <span class="count">{{scope.props.hours}}:{{scope.props.minutes}}:{{scope.props.seconds}}</span>
              <span class="icon material-icons-outlined">schedule</span>
            </div>
          </template>
        </vue-countdown-timer>
      </div>
    </div>
    <div class="card-content">
      <div class="columns is-gapless section-match match-not-expired is-multiline">
        <div class="column is-12-mobile teams">
          <div class="match-ball is-hidden-mobile">
            <span class="material-icons-outlined">
              sports_soccer
            </span>
          </div>
          <div class="team-wrapper">
            <div class="team">
              <div class="tip-number is-size-7 has-text-centered">
                1
              </div>
              <div class="flag">
                <img :src="$parent.getTeamFlag(teamHome)" />
              </div>
              <div class="name has-text-left">
                {{ $parent.getTeamName(teamHome) }}
              </div>
            </div>
            <div class="team team-guest">
              <div class="tip-number is-size-7 has-text-centered">
                2
              </div>
              <div class="flag">
                <img :src="$parent.getTeamFlag(teamGuest)" />
              </div>
              <div class="name">
                {{ $parent.getTeamName(teamGuest) }}
              </div>
            </div>
          </div>
        </div>
        <div class="column height-100 is-12-mobile tip-wrapper">

          <div class="columns height-100 is-variable is-1 is-multiline is-flex-mobile button-wrapper">
            <div class="column is-4-mobile height-100">
              <div class="tip has-background-grey-lighter has-text-centered is-size-7">1</div>
              <div class="button tips-button is-size-5"
                v-bind:class="{'is-success': myTip == '1', 'is-loading': isLoad1}"
                @click="clickTip(matchId, '1', matchInt)">

                <span>{{ xHome }}</span>

              </div>
            </div>

            <div class="column is-4-mobile height-100">
              <div class="tip has-background-grey-lighter has-text-centered is-size-7">x</div>
              <div class="button tips-button is-size-5"
                v-bind:class="{'is-success': myTip == 'x', 'is-loading': isLoad3}"
                @click="clickTip(matchId, 'x', matchInt)">

                <span>{{ xDraw }}</span>

              </div>
            </div>

            <div class="column is-4-mobile height-100">
            <div class="tip has-background-grey-lighter has-text-centered is-size-7">2</div>
              <div class="button tips-button is-size-5"
                v-bind:class="{'is-success': myTip == '2', 'is-loading': isLoad2}"
                @click="clickTip(matchId, '2', matchInt)">

                <span>{{ xGuest }}</span>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  props: {
    propMatchId: String,
    propHome: String,
    propGuest: String,
    propXHome: String,
    propXDraw: String,
    propXGuest: String,
    propStartTime: Object,
    propMatchInt: Number, //helye a listazasban
    propMyTip: String,
  },
  data(){
    return {
      isLoad: false,
      isLoad1: false,
      isLoad2: false,
      isLoad3: false,
      matchId: this.propMatchId,
      teamHome: this.propHome,
      teamGuest: this.propGuest,
      xHome: this.propXHome,
      xDraw: this.propXDraw,
      xGuest: this.propXGuest,
      startTime: this.propStartTime,
      matchInt: this.propMatchInt, //helye a listazasban
      myTip: this.propMyTip,
    }
  },
  methods: {
    checkingMatchTime(matchId) {
      var filtered = ''

    },
    clickTip(matchId, tip, i) {

      if(this.isLoad) {
        return
      } else {
        this.isLoad = true
      }

      //console.log(this.startTime.seconds)

      //ellenorzom, hogy a meccs el kezdodott e, es a user csak akkor tippelhet, ha meg nem.
      if (this.$parent.getExpiryTime(this.startTime) > 0) {

        var myAllTips = this.$parent.myAllTips

        if(tip == 1) {
          this.isLoad1 = true
          this.myTip = '1'
        } else if (tip == 2) {
          this.isLoad2 = true
          this.myTip = '2'
        } else {
          this.isLoad3 = true
          this.myTip = 'x'
        }

        //elkerem a tippjeimbol, a match id-t, hogy erre tippeltem e mar:
        var checkMyTipOnMatch = this.$parent.filterMyTip(matchId)

        if(checkMyTipOnMatch) {
          this.updateMyTip(checkMyTipOnMatch.id, tip)
        } else {
          var myTip = {
            user: this.$parent.userId,
            match: matchId,
            tip: tip,
          }
          this.addNewTip(myTip)
        }

      } else {
        this.$Msg.error('Erre a meccsre már nem lehet tippelni.', {
          position: 'top-right',
        })
      }

    },
    updateMyTip(updateId, tip) {
      try {
        firebase.firestore().collection('tips').doc(updateId).update({tip: tip}).then(() => {
          this.$parent.getMyAllTips()

          // simulating the async request
          setTimeout(() => {
            this.isLoad = false;
            this.isLoad1 = false
            this.isLoad2 = false
            this.isLoad3 = false
          }, 1000)
        })
      } catch (err) {
        console.error('Error adding document: ', err)
      }
    },
    addNewTip(myTip) {
      try {
        firebase.firestore().collection('tips').add(myTip).then(() => {
          this.$parent.getMyAllTips()

          // simulating the async request
          setTimeout(() => {
            this.isLoad = false;
            this.isLoad1 = false
            this.isLoad2 = false
            this.isLoad3 = false
          }, 1000)
        })
      } catch (err) {
        console.error('Error adding document: ', err)
      }
    },
    getShortDate(t) {
      const monthNames = ["Jan", "Febr", "Marc", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
      var output = {}
      var date = new Date(t.seconds*1000); // Thu Apr 09 2020 14:28:32 GMT+0100 (British Summer Time)

      output.year = date.getFullYear() // 2020
      output.month = monthNames[date.getMonth()] // 4 (note zero index: Jan = 0, Dec = 11)
      output.day = date.getDate() // 9
      output.hours = date.getHours()
      output.minutes = date.getMinutes()

      return output
    },
  }
}
</script>

<style lang="css" scoped>
</style>
