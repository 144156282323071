<template>
  <div class="panel">
    <div class="panel-heading">
      <h2 class="title">Mérkőzések</h2>
      <p class="subtitle">Lejátszott vagy elkezdett mérkőzések</p>
    </div>
    <div class="panel-block mb-4">
      <table class="table is-fullwidth expired-matches" v-if="pastMatchesNumb > 0">
        <thead>
          <tr>
            <th class="has-text-left">Meccs</th>
            <th class="has-text-centered is-hidden-mobile">Eredmény</th>
            <th class="has-text-centered is-hidden-tablet">E</th>
            <th class="has-text-centered is-hidden-mobile">Tippek</th>
            <th class="has-text-centered is-hidden-tablet">T</th>
            <th class="has-text-centered is-hidden-mobile">Pont</th>
            <th class="has-text-centered is-hidden-tablet">P</th>
            <th class="has-text-centered is-hidden-mobile">Szorzó</th>
            <th class="has-text-centered is-hidden-tablet">*</th>
          </tr>
        </thead>
        <tbody>
          <!--<tr v-for="(e, i) in expiredMatches" :key="i" v-if="expiredMatches.length && !e.checkStart">-->
          <tr v-for="(e, i) in even(expiredMatches)" :key="i" v-if="expiredMatches.length && !e.checkStart">
            <td>
              <div class="section-match is-size-7">
                <div class="team">
                  <div class="flag">
                    <img :src="$parent.getTeamFlag(e.data().home)" />
                  </div>
                  <div class="name is-hidden-mobile">
                    {{ $parent.getTeamName(e.data().home) }}
                  </div>
                </div>
                <div class="team">
                  <div class="flag">
                    <img :src="$parent.getTeamFlag(e.data().guest)" />
                  </div>
                  <div class="name is-hidden-mobile">
                    {{ $parent.getTeamName(e.data().guest) }}
                  </div>
                </div>
              </div>
            </td>
            <td vertical-align="baseline" height="100%" class="has-text-centered" match-result>
              {{e.data().matchResult}}
            </td>
            <td class="has-text-centered" result>
              <!-- elkezdodott vagy vege, de meg nincs beirva az eredmeny -->
              <div v-if="e.data().xgood != null && getMyTipOnMatch(e.id) != 'undefined' ">
                <span class="point-check" v-bind:class="{'has-background-success': getMyTipOnMatch(e.id) == e.data().result, 'has-background-danger': getMyTipOnMatch(e.id) != e.data().result }">
                  {{ getMyTipOnMatch(e.id) }}
                </span>
                <span class="match-point">
                  {{e.data().result}}
                </span>
              </div>
              <!-- beirva az eredmeny -->
              <div v-else>
                <div v-if="getMyTipOnMatch(e.id) != 'undefined'" class="">
                  <span class="point-check has-background-grey">
                    {{ getMyTipOnMatch(e.id) }}
                  </span>
                  <span class="match-point has-text-dark">
                    -
                  </span>
                </div>
                <div v-else class="">
                  <span class="point-check has-background-grey">
                    -
                  </span>
                  <span class="match-point has-text-dark">
                    -
                  </span>
                </div>
              </div>
            </td>
            <td class="has-text-centered scored" scored>
              {{e.data().score}}
            </td>
            <td class="has-text-centered scored" scored>
              {{e.data().xgood}}
              <button v-if='propAdmin && e.data().result < 1 ' class="button is-small is-link" type="button" name="button" @click="editMatchSetup(e, i)">
                <span class="icon material-icons-outlined">
                  edit
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="width-100">
        <article class="message is-info">
          <div class="message-body">
            <span class="icon material-icons-outlined">
              info
            </span>
            <span>Még nincsenek lejátszott mérkőzések</span>
          </div>
        </article>
      </div>
    </div>

    <div class="modal" v-bind:class="{'is-active': isModal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Modal title</p>
          <button class="delete" aria-label="close" @click="isModal=!isModal"></button>
        </header>
          <section class="modal-card-body">
            <div class="form">
              <div class="field">
                <label for="">Meccs eredménye a rendes játékidőben</label>
                <div class="control">
                  <input class="input" v-model="edit.match.matchResult" type="text" name="" value="" required>
                </div>
              </div>
              <div class="field">
                <label for="">Jó tipp</label>
                <div class="control">
                  <input class="input" v-model="edit.match.result" type="text" name="" value="" required>
                </div>
              </div>
              <div class="field">
                <label for="">Szorzó beállítása</label>
                <div class="control">
                  <div class="select">
                    <select v-model="edit.match.xgood" required>
                      <option value="" selected>Válaszd ki a helyes szorzót!</option>
                      <option v-bind:value="edit.match.xhome">
                        1: {{ edit.match.xhome }}
                      </option>
                      <option v-bind:value="edit.match.xdraw">
                        x: {{ edit.match.xdraw }}
                      </option>
                      <option v-bind:value="edit.match.xguest">
                        2: {{ edit.match.xguest }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button type="submit" class="button is-success" @click="virtualUpdateMatch" v-bind:class="{'is-loading': isLoad}">Virtuális futtatás</button>
            <button type="submit" class="button is-danger" @click="updateMatch" v-bind:class="{'is-loading': isLoad}">Mentés és pontszámok frissítése</button>
            <a class="button" @click="isModal=!isModal">Cancel</a>
          </footer>
      </div>
    </div>

  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  props: {
    propMatches: Array,
    propAdmin: Boolean,
  },
  data() {
    return {
      sortBy: 'name',
      sortDirection: 'asc',

      isLoad: false,
      isModal: false,
      pastMatchesNumb: 0,
      expiredMatches: this.propMatches,
      matchTipps: [],
      editInt: Number,
      edit: {
        id: null,
        match: {
          guest: null,
          home: null,
          matchResult: null,
          result: null,
          start: null,
          xhome: null,
          xdraw: null,
          xguest: null,
          xgood: null
        }
      }
    }
  },
  methods: {
    even: function(arr) {
      // Set slice() to avoid to generate an infinite loop!
      return arr.slice().sort(function(b, a) {
        return a.data().start - b.data().start;
      });
    },
    editMatchSetup(match, i) {
      this.isModal = true
      this.edit.id = match.id
      this.editInt = i
      this.edit.match = match.data()
    },
    updateMatch() {
      this.isLoad = true

      var washingtonRef = firebase.firestore().collection('match').doc(this.edit.id)
      //frissitem a meccs eredmenyet
      return washingtonRef.update(this.edit.match)
        .then(() => {
          this.getMatchTipps()
          this.isLoad = false
          console.log('Document successfully updated!')
        })
        .catch((error) => {
          this.isLoad = false
          console.error('Error updating document: ', error)
        })
    },
    getMatchTipps() {
      this.matchTipps = []

      try {
        firebase.firestore().collection('tips').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //megkeresem azt a tippet a tippek kozott, amit eltalalt a felhasznalo.
            if(doc.data().match == this.edit.id && doc.data().tip == this.edit.match.result) {
              //updatelem a felhasznalo pontjait
              this.updateUserPoints(doc.data().user, this.edit.match.score, this.edit.match.xgood)
            }
          })

          this.$Msg.success('Meccs vegeredmenye rogzitve es a pontok frissitve', {
            position: 'top-right',
          })

          this.isModal = false

          //itt probaltam live-ba frissiteni a mezoket. valamiert nem birtam mukodesre
          //this.expiredMatches[this.editInt].data().matchResult = this.edit.match.matchResult
          //this.expiredMatches[this.editInt].data().result = this.edit.match.tip

        })
      } catch (err) {
        this.$Msg.error(err, {
          position: 'top-right',
        })
        console.log(err)
      }

    },
    updateUserPoints(userId, score, xgood) {
      var filtered = this.$parent.users.find(el => el.data().id === userId)
      var newUserPoint = (Number(score) * Number(xgood)) + Number(filtered.data().points)

      console.log('ez a user: '+filtered.data().name+', akinek ennyi pontja van:'+filtered.data().points+' kap plusz '+score+' pontot, szorozva '+xgood+' szorzoval: '+newUserPoint)

      //Modositom a user pontszamat.
      var washingtonRef = firebase.firestore().collection('users').doc(filtered.id)
      return washingtonRef.update('points', newUserPoint)
        .then(() => {
          console.log('Document successfully updated!')
        })
        .catch((error) => {
          this.isLoad = false
          console.error('Error updating document: ', error)
        })

    },
    virtualUpdateMatch() {
      this.isLoad = true
      this.virtualGetMatchTipps()
    },
    virtualGetMatchTipps() {
      this.matchTipps = []

      try {
        firebase.firestore().collection('tips').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //megkeresem azt a tippet a tippek kozott, amit eltalalt a felhasznalo.
            if(doc.data().match == this.edit.id && doc.data().tip == this.edit.match.result) {
              //updatelem a felhasznalo pontjait - de csak virtualisan
              this.virtualUpdateUserPoints(doc.data().user, this.edit.match.score, this.edit.match.xgood)
            }
          })

          this.$Msg.success('Virtualis meccs vegeredmenye rendben lefutott', {
            position: 'top-right',
          })

          this.isLoad = false
          //this.isModal = false

        })
      } catch (err) {
        this.$Msg.error(err, {
          position: 'top-right',
        })
        console.log(err)
      }
    },
    virtualUpdateUserPoints(userId, score, xgood) {
      var filtered = this.$parent.users.find(el => el.data().id === userId)
      var newUserPoint = (Number(score) * Number(xgood)) + Number(filtered.data().points)
      console.log('ez a user: '+filtered.data().name+', akinek ennyi pontja van:'+filtered.data().points+' kap plusz '+score+' pontot, szorozva '+xgood+' szorzoval: '+newUserPoint)
    },
    getMyTipOnMatch(matchId) {
      var filtered = this.$parent.myAllTips.find(el => el.data().match === matchId)

      if (filtered) {
        return filtered.data().tip
      } else {
        return 'undefined'
      }
    },

  },
  created() {

    //console.log(this.$parent.myAllTips)

    firebase.firestore().collection('match').orderBy('start', 'desc').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (this.$parent.getExpiryTime(doc.data().start) < 0) {
          this.pastMatchesNumb++
        }
      })
    })
  }
}
</script>

<style lang="css" scoped>
</style>
