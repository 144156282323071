<template>
  <div class="home">

    <nav class="navbar is-transparent" role="navigation" aria-label="main navigation">
      <div class="container navbar-mobile">
        <div class="navbar-brand vcenter">
          <a class="navbar-item" href="/">
            <img class="brand-logo" src="../assets/images/worksdot-logo-white.svg" />
          </a>
        </div>
        <div class="navbar-menu is-show-mobile is-block">
          <div class="navbar-end vcenter">
            <div v-if='loggedIn' class="section-logout">
              <p class="p-4 user">Hello {{ userName }}!</p>

              <!-- admin section -->
              <newMatch v-if="userProfile.admin" />
              <!-- admin section -->

              <button class="button is-danger" @click="logout">
                <span class="icon material-icons-outlined">
                  logout
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <heroSection />

    <section class="section-content">



      <section class="section">
        <div class="container">

          <infoSection />

          <div class="columns is-multiline">
            <div class="column is-6-desktop is-12-tablet">

              <div class="panel">
                <div class="panel-heading">
                  <h1 class="title">Következő mérkőzések</h1>
                  <p class="subtitle is-thin">Itt tudsz tippelni a kezdő sípszóig</p>
                </div>
                <div class="panel-block" v-for="(e, i) in matches" :key="i" v-if="futureMatches > 0 && e.checkStart">

                  <div class="card next-matches-section">

                      <matchesNotExpired
                        v-bind:propMatchId="e.id"
                        v-bind:propHome="e.data().home"
                        v-bind:propGuest="e.data().guest"
                        v-bind:propStartTime="e.data().start"
                        v-bind:propXHome="e.data().xhome"
                        v-bind:propXDraw="e.data().xdraw"
                        v-bind:propXGuest="e.data().xguest"
                        v-bind:propMatchInt="i"
                        v-bind:propMyTip="e.myTip" />

                  </div>

                  <div v-if="futureMatches == 0" class="">
                    <article class="message is-info">
                      <div class="message-body">
                        <span class="icon material-icons-outlined">
                          info
                        </span>
                        <span><strong>WHOPSZ!</strong> Elfogytak a meccsek.</span>
                      </div>
                    </article>
                  </div>

                </div>
              </div>

            </div>
            <div class="column is-6-desktop is-12-tablet">

              <statSection v-bind:propUsers="users" />

              <matchesExpired v-bind:propMatches="matches" v-bind:propAdmin="userProfile.admin" />

            </div>
          </div>
        </div>
      </section>

    </section>

    <footerSection v-bind:propVersion="appVersion" />

  </div>
</template>

<script>
import {version} from '../../package'
import firebase from 'firebase/app'
import 'firebase/firestore'

import newMatch from '@/components/newMatch.vue'
import matchesExpired from '@/components/matchesExpired.vue'
import matchesNotExpired from '@/components/matchesNotExpired.vue'
import footerSection from '@/components/footer.vue'
import heroSection from '@/components/hero.vue'
import infoSection from '@/components/info.vue'
import statSection from '@/components/stat.vue'

export default {
  title: 'Worksdot EB21',
  components: {
    newMatch, matchesExpired, matchesNotExpired, footerSection, heroSection, infoSection, statSection
  },
  name: 'Home',
  data() {
    return {
      loggedIn: false,
      userName: '',
      userId: '',
      users: [],
      userProfile: [],
      appVersion: version,
      futureMatches: 0,
      teams: [],
      matches: [],
      myAllTips: [],
    }
  },
  methods: {
    endCallBack: function(i) {
      this.matches[i].checkStart = false
      this.futureMatches--
      this.watcher()
    },
    watcher () {
      firebase.firestore().collection('match').orderBy('start').onSnapshot((querySnapshot) => {
        this.matches = []
        this.futureMatches = 0

        querySnapshot.forEach((doc) => {
          doc.checkStart = this.checkExpiry(doc.data().start)

          if (this.getExpiryTime(doc.data().start) > 0) {
            this.futureMatches++
          }

          this.matches.push(doc)

        })
      })
    },
    getTeamName (id) {
      var filtered = this.teams.find(el => el.id === id)
      if (filtered) {
        return filtered.data().name
      } else {
        return 'undefined'
      }
    },
    getTeamFlag (id) {
      var filtered = this.teams.find(el => el.id === id)
      if (filtered) {
        return filtered.data().flag
      } else {
        return 'undefined'
      }
    },
    async getTeams() {
      try {
        firebase.firestore().collection('teams').orderBy('name', 'asc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.teams.push(doc)
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async getMatches() {
      this.futureMatches = 0;
      try {
        firebase.firestore().collection('match').orderBy('start').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.checkStart = this.checkExpiry(doc.data().start)

            //checkolom, hogy erre a meccsre mar tippelt e a bejelentkezett user
            var tip = this.getMyPastTip(doc.id)

            if(tip) {
              doc.myTip = tip
            } else {
              doc.myTip = '0'
            }

            if (this.getExpiryTime(doc.data().start) > 0) {
              this.futureMatches++
            }

            this.matches.push(doc)
          })
        })

      } catch (err) {
        console.log(err)
      }
    },
    getMyPastTip(mID) {
      var filter = this.filterMyTip(mID)
      if(filter) {
        if(mID == filter.data().match) {
          return filter.data().tip
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getExpiryTime(date) {
      const currentTime = new Date(date.seconds*1000)
      var timer = new Date(currentTime).getTime() - new Date().getTime();
      return timer
    },
    checkExpiry(date) {
      var ok = false

      if (this.getExpiryTime(date) < 0) {
        ok = false
      } else {
        ok = true
      }

      return ok
    },

    async logout () {
      try {
        const data = await firebase.auth().signOut()
        document.location.href="/"
      } catch (err) {
        console.log(err)
      }
    },
    getAllUsers() {
      try {
        firebase.firestore().collection('users').orderBy('points', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.users.push(doc)
            if(doc.data().id == this.userId) {
              this.userProfile = {
                id: doc.data().id,
                name: doc.data().name,
                email: doc.data().email,
                admin: doc.data().admin,
                points: doc.data().points
              }
            }
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    filterMyTip(matchID) {
      var filteredMatch = this.myAllTips.find(el => el.data().match === matchID)

      if (filteredMatch) {
        return filteredMatch
      } else {
        return false
      }

    },
    getMyAllTips() {
      this.myAllTips = []
      try {
        firebase.firestore().collection('tips').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if(doc.data().user == this.userId) {
              this.myAllTips.push(doc)
            }
          })
        })
      } catch (err) {
        console.log(err)
      }
    }

  },
  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.loggedIn = !!user
      if (user) {
        this.userName = user.displayName
        this.userId = user.uid
        //console.log(user.uid)
        this.loggedIn = true
        this.getAllUsers()
      } else {
        this.loggedIn = false
      }

    })
    this.getTeams()
    this.getMyAllTips()
    this.getMatches()
  },
  mounted() {

  }

}
</script>
