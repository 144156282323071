<template>
  <div class="footer">
    <div class="content has-text-centered section-footer">
      <div class="p-4 has-text-centered eu-logo">
        <img src="../assets/images/EURO_2020_Logo_Pt_OnDark_FC_CMYK.png" alt="" />
      </div>
      <p class="pb-0 mb-1">EB játék by <strong>jocc</strong> from <a href="http://worksdot.hu" target="_blank">Worksdot</a></p>
      <p class="is-size-7 strong">WebApp Version: {{ version }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propVersion: String
  },
  data() {
    return {
      version: this.propVersion
    }
  }
}
</script>

<style lang="css" scoped>
</style>
