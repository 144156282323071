import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/app'
import VueCountdownTimer from 'vuejs-countdown-timer'

import titleMixin from './titleMixin'
Vue.mixin(titleMixin)

import Msg from '@morioh/v-msg'
Vue.use(Msg)

Vue.use(VueCountdownTimer)
Vue.config.productionTip = false

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBcDZz_ibD8R2pHTA4I8jEqzlnHimRj6MU",
  authDomain: "eu21-53a12.firebaseapp.com",
  projectId: "eu21-53a12",
  storageBucket: "eu21-53a12.appspot.com",
  messagingSenderId: "529217081891",
  appId: "1:529217081891:web:8d30820b9c3fee524b5796"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

let app

firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
