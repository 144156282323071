<template>
  <div class="hero is-dark">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-8-desktop is-10-tablet is-12-mobile">
            <h1 class="title is-1 is-size-2-mobile">
              Tippelj a meccsekre!
            </h1>
            <h3 class="subtitle is-thin is-size-6-mobile">
              és legyél Te a Works. EB játék győztese!
            </h3>
          </div>
          <div class="column is-hidden-mobile">
            <img class="hero-image" src="../assets/images/budapest.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
