<template>
  <section class="hero is-primary is-fullheight login">
    <div class="hero-body">

      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8-tablet is-4-desktop is-4-widescreen">

            <div class="card">
              <header class="card-header has-background-dark has-text-light is-size-6 p-3">
                Elfelejtett jelszó
              </header>
              <div class="box">
                <form class="" v-on:submit.prevent="forgotPassword" method="post">
                  <div class="field">
                    <label for="" class="label">Add meg a regisztrált e-mail címed!</label>
                    <div class="control has-icons-left">
                      <input type="email" placeholder="e.g. bobsmith@gmail.com" v-model="email" class="input" required>
                      <span class="icon is-small is-left">
                        <span class="material-icons-outlined">
                          email
                        </span>
                      </span>
                    </div>
                  </div>

                  <div class="field form-buttons mt-5">
                    <input type="submit" class="button is-primary" value="E-mail küldése" />
                    <div class="mt-3 has-text-centered">
                      <router-link class="button is-ghost is-small" :to="{ name: 'Login', params: {} }">Vissza a bejelentkezésre</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </section>

</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  title: 'Worksdot EB21 - Elfelejtett jelszó',
  components: {

  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    forgotPassword() {
      var auth = firebase.auth();
      var emailAddress = this.email;

      auth.sendPasswordResetEmail(emailAddress).then(function() {
        alert('e-mail elkuldve!')
        this.$router.replace({ name: 'Home' })
      }).catch(function(error) {
        // An error happened.
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>
